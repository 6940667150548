import ajax from './ajax'
export default {
  ersRequestByCustCode(custCode) {
    return ajax.get('/ers-req/cust-code/' + custCode)
  },
  // #api-ers-request-getErsRequestByQuery
  ersRequestQuery(detail) {
    return ajax.get('/ers-req/query', detail)
  },
  ersRequestById(ersId) {
    return ajax.get('/ers-req/id/' + ersId)
  },
  // #api-ers-request-putErsData
  ersRequestUpdate(item) {
    return ajax.put('/ers-req/data/' + item.ersId, item)
  },
  searchAgencyByRegion(search) {
    if (!search) search = 'ALL'
    return ajax.get('/agency/region/' + encodeURIComponent(search))
  },
  searchAgencyByName(search) {
    if (!search) search = 'ALL'
    return ajax.get('/agency/name/' + encodeURIComponent(search))
  },
  agentByAgencyId(agencyId) {
    return ajax.get('/agent/agency-id/' + agencyId)
  },
  ersRequestAgency(ersId, agency) {
    return ajax.put('/ers-req/agency/' + ersId, {agencyId: agency._id})
  },
  ersRequestAgent(ersId, agent) {
    return ajax.put('/ers-req/agent/' + ersId, {agentId: agent._id})
  },
  getSocarHistory(ersKey) {
    return ajax.get('/ers-req/getSocarHistory/' + ersKey)
  },
  resendSocarMessage(type, id) {
    return ajax.get('/ers-req/resendSocarMessage/' + type + '/' + id)
  },
  listCustomers() {
    return ajax.get('/customer/center')
  },
  // #api-ers-request-postErsRequestByAgency
  postErsRequestByAgency(item) {
    return ajax.post('/ers-req', item)
  },
  // #api-agency-updateAgencyRemark
  updateAgencyRemark(agencyId, msg) {
    return ajax.put('/agency/remark/' + agencyId, {remark: msg})
  },
  // #api-geoAPI-getAddrByPlace
  getAddrByPlace(search) {
    return ajax.get('/geo/place/' + encodeURIComponent(search))
  },
  // #api-geoAPI-getAddrByAddrWord
  getAddrByAddrWord(search) {
    return ajax.get('/geo/address/' + encodeURIComponent(search))
  },
  // #api-message-messageOverKakao
  sendMessageKakao(agencyCode, message, userIds, ersId = null) {
    return ajax.post('/message/at', {
      wgCode: agencyCode,
      message: ersId === null ? message : ersId + '건 ' + message,
      userIds: userIds
    })
  },
  // #api-geoAPI-getAgentsByErsLocation
  getAgentsByErsLocation(x, y, meter = 1000) {
    return ajax.get('/geo/nearby/' + meter + '/' + x + '/' + y)
  },
  // #api-message-getMessageReceives
  getMessageReceives() {
    return ajax.get('/message')
  },
  // #api-ers-request-putErsCancel 접수불가 취소
  putErsCancel(ersId, message) {
    return ajax.put('ers-req/cancel/' + ersId, {cancelReason: message})
  },
  // #api-partner-getPartnersNearby
  // #api-partner-getPartnersNearby 파트너 입고지 조회
  getPartnersNearby(longitude, latitude, custCode, name, dist = null) {
    return ajax.get('/partner/nearby', {
      x: longitude,
      y: latitude,
      custCode: custCode,
      name: name,
      dist: dist
    })
  },
  // #api-task-getTasksByQuery 출동 작업 조회
  getTasksByQuery(options) {
    return ajax.get('/task/query', options)
  },
  // #api-message-getMessageTemplates 템플릿 조회
  getMessageTemplates(classCode = 'result') {
    return ajax.get('/message/template/' + classCode)
  },
  // #api-task-putTaskConfirm 작업내용 승인
  putTaskConfirm(taskId) {
    return ajax.put('/task/confirm/' + taskId)
  },
  // #api-task-putTaskConfirmCancel 작업내용 승인 취소
  putTaskConfirmCancel(taskId) {
    return ajax.put('/task/confirm-cancel/' + taskId)
  },
  // #api-task-getTaskAndTaskProofByTaskId 작업내용 조회
  getTaskAndTaskProofByTaskId(taskId) {
    return ajax.get('/task/' + taskId)
  },
  // #api-task-getProofImageBase64ByProofId 사진조회
  getProofImageBase64ByProofId(proofId) {
    return ajax.get('/task/image/' + proofId)
  },
  // #api-task-getTaskAndTaskProofsByTaskId
  getTaskProofsByTaskId(taskId) {
    return ajax.get('/task/proofs/' + taskId)
  },
  // #api-counselor-getCounselors 상담사 리스트
  getCounselors() {
    return ajax.get('/counselor')
  },
  // #api-workgroup-getWorkgroup 워킹 그룹 조회
  getWorkgroup() {
    return ajax.get('/workgroup')
  },
  // 상담사 등록
  postCounselor(item) {
    return ajax.post('/counselor', item)
  },
  putCounselor(id, item) {
    return ajax.put('/counselor/' + id, item)
  },
  // #api-auth-setPwdChange 비밀번호 변경
  passwordChange(current, change, confirm) {
    return ajax.post('/auth/pwd-change', { currPwd: current, newPwd: change, rePwd: confirm })
  },
  // #api-auth-initPassword 비밀번호 초기화
  passwordReset(userId) {
    return ajax.post('/auth/pwd-reset', { userId: userId })
  },
  // #api-workgroup-postWorkgroup
  postWorkgroup(item) {
    return ajax.post('/workgroup', item)
  },
  deleteWorkgroup(id) {
    return ajax.delete('/workgroup/' + id)
  },
  putWorkgroup(id, item) {
    return ajax.put('/workgroup/' + id, item)
  },
  postMessageTemplate(item) {
    return ajax.post('/message/template', item)
  },
  putMessageTemplate(id, item) {
    return ajax.put('/message/template/' + id, item)
  },
  deleteMessageTemplate(id) {
    return ajax.delete('/message/template/' + id)
  },
  getMessageTemplateAll() {
    return ajax.get('/message/template')
  },
  getCenter(code) {
    return ajax.get('/center/' + code)
  },
  // 메모 등록 #api-task-putTaskMemo
  putTaskMemo(taskId, memo) {
    return ajax.put('/task/memo/' + taskId, { memo: memo })
  },
  // #api-auth-setPwdChangeOnLogin
  pwdChangeOnLogin(item, id, type, errorHandler) {
    item.userId = id
    item.changeType = type
    return ajax.post('/auth/pwd-change-on-login', item, errorHandler)
  },
  // #api-agent-setAgentRequestConfirmByCenter
  setAgentRequestConfirmByCenter(id) {
    return ajax.put('/agent/request-confirm/' + id)
  },
  // #api-message-sendMessageOverWebSocket 메시지 전송
  sendMemo(wgCode, message, userIds = [],) {
    return ajax.post('/message/ws', {
      wgCode: wgCode,
      userIds: userIds,
      message: message
    })
  },
  // #api-auth-initAgentPassword 기사비밀번호 초기화 요청
  initAgentPassword(userId) {
    return ajax.post('/auth/agent-pwd-reset', {userId: userId})
  }
}
