<template>
  <vue-perfect-scrollbar
    :settings="{suppressScrollX: true, wheelPropagation: false}"
    class="h-100 rtl-ps-none ps scroll"
    style="height: 100%;"
  >
    <div class="pa-5">
      <v-app-bar
        :color="$vuetify.theme.dark ? 'dark' : 'grey lighten-4'"
        :dark="$vuetify.theme.dark"
        class="pt-1"
        fixed
        flat
      >
        <div class="d-flex justify-space-between align-center w-full">
          <h6 class="ma-0">
            메시지
          </h6>
          <slot name="rightSideBarCloseButton" />
        </div>
      </v-app-bar>
    </div>
    <div class="pa-5 mt-0" v-if="centerMessages.length > 0">
      <v-row dense v-for="msg in centerMessages" :key="msg._id">
        <v-col>
          <v-card outlined class="ma-1">
            <v-card-text>
              <div class="msg-date"><v-icon small class="mr-1">mdi-alarm</v-icon>{{moment(msg.createdAt).format('MM/DD HH:mm')}}</div>
              <div class="msg-text"><v-icon small class="mr-1">mdi-card-text-outline</v-icon>{{msg.message}}</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-row dense v-else class="message">
      <v-col>
        메시지가 없습니다.
      </v-col>
    </v-row>
  </vue-perfect-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/services/api'
import moment from 'moment'
export default {
  name: 'RightSideBar',
  props: {},
  data() {
    return {
      moment
    }
  },
  computed: {
    ...mapGetters(['centerMessages'])
  },
  methods: {
    list() {
      api.getMessageReceives().then(r => {
        this.$store.commit('setCenterMessages', r.result)
      })
    }
  },
  mounted() {
    this.list()
  }
}
</script>

<style scoped>
.message {
  margin-top: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

</style>
